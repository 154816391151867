import { useFormContext } from "react-hook-form";
import { IDGHeaders, Quote } from "types";


const useApiHeaders = (): IDGHeaders => {
    const { getValues } = useFormContext<Quote>();
    const [apiKey, networkId] = getValues(['apiKey', 'networkId']);

    return {
        'x-network-id': networkId,
        'api-key': apiKey
    }
}

export default useApiHeaders;