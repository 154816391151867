import { Quote } from "types";
import slugify from "./slugify";

const extractLocation = (
  formEntries: Quote
): {
  slug: string;
  lng: string;
  lat: string;
  address?: string;
} => {
  if (formEntries.location.location) {
    return {
      slug: `${slugify(formEntries.location.location.city)}-${
        formEntries.location.location.postal_code
      }`,
      lat: formEntries.location.location.geo_coordinates.latitude.toString(),
      lng: formEntries.location.location.geo_coordinates.longitude.toString(),
      address: formEntries.location.location.address,
    };
  }
  return {
    slug: "autour-de-moi",
    lat: formEntries.location.userPosition.latitude.toString(),
    lng: formEntries.location.userPosition.longitude.toString(),
  };
};

const extractVehicle = (formEntries: Quote): string => {
  if (formEntries.vehicle.identifier.identifier) {
    return formEntries.vehicle.identifier.identifier.replaceAll(/-|\s/g, "");
  }
  const make = formEntries.vehicle.model.make.id;
  const model = formEntries.vehicle.model.model.id;
  const modelversion = formEntries.vehicle.model.model.child.id;
  const version = formEntries.vehicle.model.version.id.replace(
    `${make}_${model}_${modelversion}_`,
    ``
  );
  return `${make}/${model}_${modelversion}/${version}`;
};

const generateServicesIds = (formEntries: Quote): string => {
  const serviceIds = formEntries.services.reduce((result, service, index) => {
    const serviceId = service.child.id;
    const dash = index === formEntries.services.length - 1 ? "" : "-";
    return (result += `${serviceId}${dash}`);
  }, "");
  return serviceIds;
};

export const generateRedirectionURL = (formEntries: Quote): string => {
  const { locale } = formEntries;
  const {
    slug: locationSlug,
    lat,
    lng,
    address,
  } = extractLocation(formEntries);
  const serviceIds = generateServicesIds(formEntries);
  const vehicle = extractVehicle(formEntries);
  const url = `/${locale}/devis/${locationSlug}/${serviceIds}/${vehicle}?lng=${lng}&lat=${lat}${
    address ? `&address=${address}` : ""
  }`;
  return url;
};

type redirectProps = {
  url: string;
  redirectDomain?: string;
  autoRedirect: "true" | "_blank";
  apiDomain: string;
  networkId: string;
};
export const redirectToDevis = ({
  url,
  redirectDomain,
  autoRedirect,
  apiDomain,
  networkId,
}: redirectProps): void => {
  const shouldAddTrackingParams = networkId !== "IDG";
  const domain = redirectDomain ?? apiDomain.replace("api", "www");

  let completeURL: string;
  if (shouldAddTrackingParams) {
    const lowercasedNetworkId = networkId.toLowerCase();
    const eulerianTrackingLink = `&ept-publisher=${lowercasedNetworkId}&ept-name=widget-${lowercasedNetworkId}&utm_medium=${lowercasedNetworkId}`;
    completeURL = `${domain}${url}&utm_source=partenariat${eulerianTrackingLink}`;
  } else {
    completeURL = `${domain}${url}`;
  }
  if (autoRedirect === "_blank") {
    redirectInNewWindow(completeURL);
  } else {
    redirectToUrl(completeURL);
  }
};

const redirectInNewWindow = (url: string): void => {
  window.open(url, "_blank");
};

const redirectToUrl = (url: string): void => {
  window.location.href = url;
};
