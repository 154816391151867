import { useState, useEffect, useRef } from "preact/hooks";
import { h, VNode } from "preact";
import { Combobox } from "@headlessui/react";
import { SearchIcon } from "../../icons";
import isMobile from "../../utils/isMobile";
import { Category, Option, SelectedCategory } from "types";

interface SearchFilterProps {
  options: Option[] | Category[];
  placeholder: string;
  value: Option;
  onChange: (...event: Option[]) => void;
  setFilteredOptions: (options: Option[] | Category[]) => void;
}

export const isCategories = (options: Category[] | Option[]): boolean => {
  return typeof (options[0] as Category)?.children === "object" || false;
};

const SearchFilter = (props: SearchFilterProps): VNode => {
  const [query, setQuery] = useState("");
  const inputRef = useRef(null);
  const { options, placeholder, value, onChange, setFilteredOptions } = props;
  const areOptionsCategories = isCategories(options);

  useEffect(() => {
    if (!isMobile()) inputRef?.current?.focus();
  }, [inputRef, options]);

  useEffect(() => {
    const filteredOptions = filterOptions(query, options, areOptionsCategories);
    if (filteredOptions.length > 0) setFilteredOptions(filteredOptions);
  }, [query, areOptionsCategories, options, setFilteredOptions]);

  return (
    <Combobox value={value} onChange={onChange}>
      <div className="flex w-full cursor-default overflow-hidden border border-grey-soft rounded-idg">
        <Combobox.Input
          className="w-full h-14 pl-4 focus:outline-none"
          onChange={(event) =>
            setQuery((event.target as HTMLInputElement).value)
          }
          displayValue={(option: Option) => option?.label || ""}
          placeholder={placeholder}
          ref={inputRef}
        />
        <div className="flex items-center pr-4 pl-1">
          <SearchIcon />
        </div>
      </div>
    </Combobox>
  );
};

export const categoriesToSelectedCategories = (
  categories: Category[]
): SelectedCategory[] => {
  return categories.reduce(
    (transformedOptions: SelectedCategory[], category: Category) => [
      ...transformedOptions,
      ...category.children.map((child) => ({
        id: category.id,
        label: category.label,
        child,
      })),
    ],
    []
  );
};

const toLowerCaseWithoutAccent = (str: string): string =>
  str
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");

const filteringFunctionFactory =
  (query) =>
  ({ label }) =>
    toLowerCaseWithoutAccent(label).includes(toLowerCaseWithoutAccent(query));

const filterOptions = (
  query: string,
  options: Option[] | Category[],
  areOptionsCategories: boolean
): Option[] | Category[] => {
  const queryFilterFunction = filteringFunctionFactory(query);

  if (areOptionsCategories) {
    return (options as Category[])
      .map((option) => {
        const children = option.children.filter(queryFilterFunction);
        return { ...option, children };
      })
      .filter((option) => option.children.length);
  }
  return (options as Option[]).filter(queryFilterFunction);
};

export default SearchFilter;
