import type { MutableRef } from "preact/hooks";

export const scrollToTop = (
  quoteformRef: MutableRef<HTMLFormElement>
): void => {
    const OFFSET = 50;
    const bodyRect = document.body.getBoundingClientRect();
    const quoteFormRect = quoteformRef.current.getBoundingClientRect();
    const quoteformPosition = quoteFormRect.top - bodyRect.top;

  window.scrollTo({
    top: quoteformPosition + OFFSET,
    behavior: "smooth",
  });
};
