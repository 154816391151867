import { h, VNode, Fragment } from "preact";
import { Transition } from "@headlessui/react";
import { CrossIcon } from "../../icons";
import { Color } from "../../utils/css-variables";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  title?: string | VNode;
  children?: VNode;
}

const stopPropagation = (e) => e.stopPropagation();
const Modal = ({ isOpen, closeModal, title, children }: ModalProps): VNode => {
  return (
    <Transition appear show={isOpen}>
      <div className="relative z-10">
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-25"
          leave="ease-in duration-200"
          leaveFrom="opacity-25"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto" onClick={closeModal}>
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="w-[320px] lg:w-[448px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative"
                onClick={stopPropagation}
              >
                <>
                  <h3 className="text-lg font-medium leading-6 flex justify-between items-center">
                    <>
                      {title}
                      <div className="cursor-pointer" onClick={closeModal}>
                        <CrossIcon color={Color("primary")} />
                      </div>
                    </>
                  </h3>
                  {children}
                </>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default Modal;
