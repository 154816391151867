import { IDGHeaders, Locale } from "types";
import { deconstructLocale } from "../../utils/locale";
import { Vehicle } from "./types";

export const getVehicleByRegistrationNumber = (registrationNumber: string, locale: Locale = 'fr-fr', headers: IDGHeaders, apiDomain: string): Promise<Vehicle | null> => {
    const endpoint = new URL(`${apiDomain}/car-identification/search/registration/${registrationNumber}`);
    const [, country] = deconstructLocale(locale)

    return fetch(endpoint, {
        headers: {
            ...headers,
            countryCode: country
        },
    })
        .then((response) => {
            if (response.status >= 400) {
                if (response.status === 404) {
                    return null;
                }
                throw new Error("Error fetching data");
            }
            return response.json();
        })
        .then((res: Vehicle[]) => res?.[0] || null);
}

type getVehicleByVINDTO = {
    score: number;
    vehicleId: string;
}
export const getVehicleByVIN = (VIN: string, headers: IDGHeaders, apiDomain: string): Promise<getVehicleByVINDTO | null> => {
    const endpoint = new URL(`${apiDomain}/vehicle-identifiers/vins/${VIN}?country-code=be`);

    return fetch(endpoint, {
        headers
    })
        .then((response) => {
            if (response.status >= 400) {
                throw new Error("Error fetching data");
            } else {
                return response.json();
            }
        })
        .then((res: { data: getVehicleByVINDTO[] }) => res.data?.[0] || null);
}

export const getVehicleById = (vehicleId: string, headers: IDGHeaders, apiDomain: string): Promise<Vehicle | null> => {
    const endpoint = new URL(`${apiDomain}/car-identification/vehicles/${vehicleId}`);

    return fetch(endpoint, {
        headers: {
            ...headers,
            countryCode: 'fr'
        },
    })
        .then((response) => {
            if (response.status >= 400) {
                throw new Error("Error fetching data");
            } else {
                return response.json();
            }
        })
        .then((res: Vehicle) => res || null);
}