import { useCallback, useEffect } from "preact/hooks";
import { Control, useFormContext } from "react-hook-form";
import { attachCallback } from "../utils/callbacks";
import { LocationWithQuery, Quote } from "types";

function areAllKeysFilled(location: LocationWithQuery): boolean {
  return Object.values(location).every((value) =>
      value !== undefined && !(typeof value === 'string' && value.trim() === '')
  );
}

export default function useLocationCallback(control: Control<Quote>): void {
  const { setValue } = useFormContext<Quote>();

  const setLocation = useCallback(
    (location: LocationWithQuery) => {
      if (areAllKeysFilled(location)) {
        setValue('location', location)
      }
    },
    [setValue]
  );

  useEffect(
    () => attachCallback({ key: "setLocation", callback: setLocation }),
    [setLocation]
  );
}