import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { CarService, ServiceCategory } from "../../../api/service/types";
import { getMockedServiceCategories } from "../../../api/mocks/services/get-service-categories";
import useLocale from "../use-locale";
import { useVehicleEnergyType } from "../use-vehicle-energy-type";
import useServices from "../use-services";
import { EnergyType } from "src/api/vehicle/types";
import { Locale, SelectedCategory } from "types";

const useServiceCategories = (): UseQueryResult<
  [] | ServiceCategory[],
  unknown
> => {
  const locale = useLocale();
  const vehicleEnergyType = useVehicleEnergyType();
  const { data: allServicesCategories } = useServices();

  const query = useQuery(
    ["service-categories", vehicleEnergyType],
    () =>
      getCompatibleServices(
        vehicleEnergyType,
        getMockedServiceCategories(locale),
        getServiceIdsbyEnergyType(allServicesCategories, vehicleEnergyType)
      ),
    {
      enabled: !!allServicesCategories,
    }
  );
  return { ...query };
};
export default useServiceCategories;

export const getServiceIdsbyEnergyType = (
  allServicesCategories: CarService[],
  vehicleEnergyType: EnergyType
): { [key: string]: boolean } => {
  const filteredServices = allServicesCategories?.filter((service) =>
    isServiceCompatible(service, vehicleEnergyType)
  );
  return filteredServices.reduce(
    (serviceIds, service) => ({ ...serviceIds, [service.id]: true }),
    {}
  );
};

export const isServiceCompatible = (
  service: CarService,
  vehicleEnergyType: EnergyType
): boolean => {
  if (!service.fuel) return true;
  return service.fuel.includes(vehicleEnergyType);
};

export const getCompatibleServices = (
  vehicleEnergyType: EnergyType | "",
  serviceCategories: ServiceCategory[],
  compatibleServiceIds: { [key: string]: boolean }
): ServiceCategory[] => {
  if (!vehicleEnergyType) return serviceCategories;
  return serviceCategories.map((category) => {
    return {
      ...category,
      children: category.children.filter(
        (service) => compatibleServiceIds[service.id]
      ),
    };
  });
};

export const getServiceById = (
  serviceId: string,
  locale: Locale
): SelectedCategory | undefined => {
  const allServicesCategories = getMockedServiceCategories(locale);
  for (const serviceCategory of allServicesCategories) {
    const service = serviceCategory.children.find(
      (service) => service.id === serviceId
    );
    if (service) {
      return {
        id: serviceCategory.id,
        label: serviceCategory.label,
        child: service,
      };
    }
  }
  return undefined;
};
