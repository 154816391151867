import { FunctionComponent, h } from "preact";
import { CrossIcon } from "../../icons";

interface TagProps {
  handleRemove?: () => void;
  label: string;
}

const Tag: FunctionComponent<TagProps> = ({ handleRemove, label }) => (
  <div className="flex justify-between w-full items-center p-2 font-bold">
    {label}
    <div className="shrink-0 pl-1" onClick={handleRemove}>
      <CrossIcon color={"red"} />
    </div>
  </div>
);

export default Tag;
