import { useEffect, useCallback } from "preact/hooks";
import { h, VNode } from "preact";
import { useFormContext, useController } from "react-hook-form";
import { Combobox } from "@headlessui/react";
import useDebounce from "../../../hooks/use-debounce";
import useLocationSuggestions from "../../../hooks/apis/use-location-suggestions";
import { SearchIcon } from "../../../icons";
import { Quote } from "types";
import { LocationSuggestion } from "../../../api/location/types";
import { withText } from "preact-i18n";

const queryPath = "location.query";
const locationPath = "location.location";

type LocationAutocompleteProps = {
  resetSelectedInput: () => void;
  cityOrPostalCode: string;
};

const handleFocus = (event) => {
  event.target.select();
};

const LocationAutocomplete = ({
  resetSelectedInput,
  cityOrPostalCode,
}: LocationAutocompleteProps): VNode => {
  const { control, setFocus, setValue } = useFormContext<Quote>();
  const {
    field: { value: query, onChange: setQuery, ref: queryRef },
  } = useController({ control, name: queryPath });
  const {
    field: { value: location, onChange: setLocation },
  } = useController({ control, name: locationPath });

  const debouncedQuery = useDebounce(query, 300);
  const { suggestions } = useLocationSuggestions(debouncedQuery);

  useEffect(() => {
    setFocus(queryPath);
  }, [setFocus]);

  const handleQueryChange = (...event): void => {
    const newQuery = (event[0].target as HTMLInputElement).value;
    if (newQuery === "") setLocation(undefined);
    setQuery(newQuery);
  };

  const handleSelectLocation = useCallback(
    (...event) => {
      setLocation(event[0]);
      setValue("location.userPosition", {
        latitude: undefined,
        longitude: undefined,
      });
      resetSelectedInput();
    },
    [setLocation, resetSelectedInput, setValue]
  );

  return (
    <Combobox value={location} onChange={handleSelectLocation}>
      {({ open }) => (
        <div>
          <div className="w-full flex justify-between cursor-default overflow-hidden border border-grey-soft rounded-idg">
            <Combobox.Input
              className="w-full h-14 pl-4 focus:outline-none"
              onChange={handleQueryChange}
              displayValue={(option: LocationSuggestion) => option?.label || ""}
              placeholder={cityOrPostalCode}
              onFocus={handleFocus}
              ref={queryRef}
            />
            <div className="flex items-center pr-4">
              <SearchIcon />
            </div>
          </div>
          {suggestions?.length > 0 && open && (
            <div className="border-b border-b-grey-light py-5 -mb-1">
              <Combobox.Options className="w-full -mt-2 pt-2 text-sm text-grey-charcoal">
                {suggestions?.map((option) => (
                  <Combobox.Option
                    key={`${option.geo_coordinates.latitude}-${option.geo_coordinates.longitude}`}
                    value={option}
                    className={({ active }) =>
                      `flex items-center relative cursor-pointer select-none min-h-[40px] py-1 px-2 mx-2 my-2 rounded-lg ${
                        active ? "text-white bg-dark" : ""
                      }`
                    }
                  >
                    {option.label}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </div>
          )}
        </div>
      )}
    </Combobox>
  );
};

export default withText("location.cityOrPostalCode")(LocationAutocomplete);
