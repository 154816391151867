import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { getServices } from "../../api/service/car-services";
import { CarService } from "../../api/service/types";
import useApiHeaders from "./use-api-headers";
import { useAPIDomain } from "./use-domain";
import useLocale from "./use-locale";
import { Quote } from "types";
import { useFormContext } from "react-hook-form";

const getServiceQuoteContent = (
  services: CarService[],
  serviceId: number
): CarService["quoteContent"] | undefined => {
  return services.find((service) => service.id === serviceId)?.quoteContent;
};

const useServices = (): UseQueryResult<CarService[], unknown> & {
  getServiceQuoteContent: (serviceId: number) => CarService["quoteContent"];
} => {
  const locale = useLocale();
  const headers = useApiHeaders();
  const apiDomain = useAPIDomain();
  const { getValues } = useFormContext<Quote>();
  const [vehicleIdentifier, vehicleVersion] = getValues([
    "vehicle.identifier.vehicle",
    "vehicle.model.version",
  ]);
  const enabled = !!vehicleIdentifier || !!vehicleVersion;
  const queryResults = useQuery(
    ["car-services"],
    () => getServices(locale, headers, apiDomain),
    {
      enabled,
    }
  );
  return {
    ...queryResults,
    getServiceQuoteContent: (serviceId) =>
      getServiceQuoteContent(queryResults.data, serviceId),
  };
};

export default useServices;
