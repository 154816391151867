import { h, Fragment, VNode } from "preact";
import Button from "../../atoms/button";
import Modal from "../../atoms/modal";
import { InformationIcon } from "../../../icons";
import useServices from "../../../hooks/apis/use-services";
import { SelectedCategory, Category, Option } from "types";
import { withText } from "preact-i18n";

interface InformationServiceModalProps {
  selectedService: SelectedCategory | Option;
  onChange: (...event: SelectedCategory[] | Option[]) => void;
  closeModal: () => void;
  selectButton: string;
  serviceContains: string;
}

const InformationServiceModal = ({
  onChange,
  selectedService,
  closeModal,
  selectButton,
  serviceContains,
}: InformationServiceModalProps): VNode => {
  const { getServiceQuoteContent } = useServices();
  const handleCategoryAdded = () => {
    onChange(selectedService);
    closeModal();
  };

  const { id, label } = (selectedService as SelectedCategory).child
    ? (selectedService as SelectedCategory).child
    : (selectedService as Option);
  const contentQuote = getServiceQuoteContent(Number(id));

  if (!contentQuote) return null;

  return (
    <div className="px-4">
      <Modal
        isOpen={!!selectedService}
        title={
          <div className="flex gap-4 items-center font-bold">
            <InformationIcon /> {label}
          </div>
        }
        closeModal={closeModal}
      >
        <>
          <div className="flex flex-col py-5 gap-4">
            {contentQuote.length > 0 && (
              <div className="flex flex-col gap-3">
                <h3 className="text-primary font-bold">{serviceContains}</h3>
                <ul className="flex flex-col gap-2 list-disc pl-4">
                  {contentQuote[0].contentParts.map((service) => (
                    <li key={service}>{service}</li>
                  ))}
                </ul>
                {contentQuote[0].description}
              </div>
            )}
          </div>
          <Button
            className="w-full px-5"
            label={selectButton}
            onClick={handleCategoryAdded}
          />
        </>
      </Modal>
    </div>
  );
};

export default withText({
  selectButton: "service.selectButton",
  serviceContains: "service.serviceContains",
})(InformationServiceModal);

export const categoriesChildrenToOptionList = (
  categories: Category[]
): Option[] => {
  return categories.reduce(
    (transformedOptions: SelectedCategory[], category: Category) => [
      ...transformedOptions,
      ...category.children,
    ],
    []
  );
};
