import { useState, useCallback, useEffect, useMemo } from "preact/hooks";
import { h, VNode } from "preact";
import { useFormContext, useController } from "react-hook-form";
import useVehicleModel from "../../../hooks/apis/use-vehicle-model";
import { ChevronDownIcon, CrossIcon } from "../../../icons";
import FilteredSelect from "../filtered-select";
import { Category, Option, Quote, VehicleStepType } from "types";
import { scrollToTop } from "../../../utils/scroll-to";

const VehicleModelSelect = (): VNode => {
  const { control, setValue, getValues } = useFormContext<Quote>();
  const {
    field: { value: vehicleModel, onChange: setVehicleModel },
  } = useController({
    control,
    name: "vehicle.model",
  });

  const quoteformRef = useMemo(() => getValues("quoteformRef"), [getValues]);

  const [currentStep, setCurrentStep] = useState<VehicleStepType>("makes");

  const stepsOptions = useVehicleModel();

  const isFirstStep = currentStep === "makes";
  useEffect(() => {
    if (isFirstStep && vehicleModel.version) {
      setCurrentStep("versions");
    }
  }, [isFirstStep, setCurrentStep, vehicleModel]);

  const handleVehicleModelChange = useCallback(
    (...event: Option[] | Category[]) => {
      const newValue = event[0];
      switch (currentStep) {
        case "makes":
          setVehicleModel({
            make: newValue,
            model: undefined,
            version: undefined,
          });
          setCurrentStep("models");
          break;
        case "models":
          setVehicleModel({
            make: vehicleModel.make,
            model: newValue,
            version: undefined,
          });
          setCurrentStep("versions");
          break;
        case "versions":
          setVehicleModel({
            make: vehicleModel.make,
            model: vehicleModel.model,
            version: newValue,
          });
          setValue("vehicle.identifier.identifier", undefined);
          setValue("vehicle.identifier.vehicle", undefined);
          setValue("selectedInput", undefined);
          break;
      }
      scrollToTop(quoteformRef);
    },
    [currentStep, setVehicleModel, vehicleModel, setValue]
  );

  const deleteVehicleModel = useCallback(() => {
    setCurrentStep("makes");
    setVehicleModel({
      make: undefined,
      model: undefined,
      version: undefined,
    });
    setValue("services", undefined);
  }, [setVehicleModel, setValue]);

  const handleGoBack = useCallback(() => {
    if (currentStep === "models") deleteVehicleModel();
    else if (currentStep === "versions") setCurrentStep("models");
  }, [currentStep, setCurrentStep, deleteVehicleModel]);

  return (
    <div className={`flex flex-col w-full rounded-idg gap-4`}>
      {currentStep !== "makes" && (
        <div className="px-4 flex justify-between items-center max-w-full">
          <div className="flex gap-4 items-center" onClick={handleGoBack}>
            <span className="cursor-pointer">
              {<ChevronDownIcon className="rotate-90" />}
            </span>
            <div className="flex flex-col leading-none max-w-[250px] lg:max-w-[210px]">
              <span className="font-bold">
                {`${vehicleModel.make?.label || ""} ${
                  vehicleModel.model?.label || ""
                }`}
              </span>
              <span className="text-sm leading-none truncate">
                {vehicleModel.version?.label || ""}
              </span>
            </div>
          </div>
          <span className="cursor-pointer" onClick={deleteVehicleModel}>
            <CrossIcon color="red" />
          </span>
        </div>
      )}
      <FilteredSelect
        onChange={handleVehicleModelChange}
        options={stepsOptions[currentStep] || []}
        isLoading={stepsOptions.isLoading[currentStep]}
        value={vehicleModel[currentStep.slice(0, -1)]}
      />
    </div>
  );
};

export default VehicleModelSelect;
