import register from "preact-custom-element";
import QuoteForm from "../ui/organisms/quote-form";
import { h, VNode } from "preact";
import style from "../style/index.css";
import { Locale } from "types";

type WebcomponentProps = {
  primarycolor?: string;
  secondarycolor?: string;
  darkcolor?: string;
  fontfamily?: string;
  locale?: Locale;
  data?: string;
  apikey: string;
  apidomain?: string;
  redirectdomain?: string;
  graphqldomain?: string;
  networkid: string;
  autoredirect: "true" | "_blank" | "false";
  forcemobile?: string;
  persist?: boolean;
};

const WebComponent = (props: WebcomponentProps): VNode => (
  <div>
    <slot name="font" />
    <link
      rel="preload"
      as="style"
      href="https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap"
      rel="stylesheet"
    />
    <style>{style}</style>
    <QuoteForm
      primaryColor={props.primarycolor}
      secondaryColor={props.secondarycolor}
      darkColor={props.darkcolor}
      fontFamily={props.fontfamily}
      apiKey={props.apikey}
      networkId={props.networkid}
      apiDomain={props.apidomain}
      redirectDomain={props.redirectdomain}
      graphqlDomain={props.graphqldomain}
      autoRedirect={props.autoredirect}
      forceMobile={props.forcemobile === "true"}
      {...props}
    />
  </div>
);
if (typeof window !== "undefined") {
  register(
    WebComponent,
    "idg-quoteform",
    [
      "locale",
      "host",
      "fontFamily",
      "primaryColor",
      "secondaryColor",
      "darkColor",
      "data",
      "apiKey",
      "apiDomain",
      "redirectDomain",
      "graphqlDomain",
      "networkId",
      "autoRedirect",
      "forceMobile",
      "persist",
    ],
    {
      shadow: true,
    }
  );
}
export default WebComponent;
