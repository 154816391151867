import { IDGHeaders, Locale, VehicleIdentifierType } from "types";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { getVehicleByRegistrationNumber, getVehicleByVIN, getVehicleById } from "../../api/vehicle/car-identification";
import { Vehicle } from "../../api/vehicle/types";
import useApiHeaders from "./use-api-headers";
import useLocale from "./use-locale";
import { useAPIDomain } from "./use-domain";

const getVehicleByIdentifier = async (vehicleIdentifier: string, identifierType: VehicleIdentifierType, headers: IDGHeaders, locale: Locale, apiDomain: string) => {
    if (identifierType === 'VIN') {
        const vehicleVIN = await getVehicleByVIN(vehicleIdentifier, headers, apiDomain);
        if (vehicleVIN) {
            return await getVehicleById(vehicleVIN.vehicleId, headers, apiDomain);
        // eslint-disable-next-line no-else-return
        } else {
            throw new Error('Wrong VIN number.')
        }
    }

    const vehicle = await getVehicleByRegistrationNumber(vehicleIdentifier, locale, headers, apiDomain)
    if (vehicle) {
        return vehicle
    // eslint-disable-next-line no-else-return
    } else {
        throw new Error('Wrong immatriculation number.')
    }

}

const useVehicleIdentifier = (vehicleIdentifier: string, identifierType: VehicleIdentifierType, isValid: boolean,): UseQueryResult<Vehicle, unknown> => {
    const cleanedVehiculeIdentifier = vehicleIdentifier.replaceAll(/-|\s/g, "");
    const headers = useApiHeaders();
    const locale = useLocale();
    const apiDomain = useAPIDomain();
    const queryResults = useQuery(
        ["vehicleRegistrationNumber", cleanedVehiculeIdentifier],
        () => getVehicleByIdentifier(cleanedVehiculeIdentifier, identifierType, headers, locale, apiDomain),
        {
            enabled: isValid && !!vehicleIdentifier,
        }
    );
    return queryResults;
}

export default useVehicleIdentifier;