import { Component } from "preact";

class ErrorBoundary extends Component {
  state = { error: null };

  static getDerivedStateFromError(error) {
    return { error: error.message };
  }

  componentDidCatch(error) {
    /* console.error(error); */
    this.setState({ error: error.message });
  }

  render() {
    return this.props.children;
  }
}
export default ErrorBoundary;
