import { VNode, h, Fragment } from "preact";
import { IntlProvider, Text } from "preact-i18n";
import { useCallback, useEffect, useRef } from "preact/hooks";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  useForm,
  FormProvider,
  useController,
  UseFormReturn,
} from "react-hook-form";
import useFormPersist from "react-hook-form-persist";

import DebugFormData from "../atoms/debug-form-data";
import Button from "../atoms/button";
import LocationContainer from "./location-container";
import VehicleContainer from "./vehicle-container";
import { deconstructLocale } from "../../utils/locale";
import ServiceContainer from "./service-container";
import Backdrop from "../atoms/backdrop";
import { setAllCSSVariables } from "../../utils/css-variables";
import {
  defaultLocal,
  IDGDarkColor,
  IDGFontFamily,
  IDGPrimaryColor,
  IDGSecondaryColor,
} from "../../utils/constants";
import {
  generateRedirectionURL,
  redirectToDevis,
} from "../../utils/redirection";
import { submitEvent } from "../../utils/events";
import { Locale, Quote } from "types";
import {
  defaultFormValues,
  completedData,
} from "../../utils/data-initialisation-quoteform";
import { sendDatalayerEvent } from "../../utils/analytics";

import useLocalStorage from "../../hooks/use-local-storage";
import fr from "../../i18n/fr.json";
import nl from "../../i18n/nl.json";
import ErrorBoundary from "../atoms/error-boundary";
import { API_DOMAIN, GRAPHQL_DOMAIN } from "../../../src/api/constants";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
  },
});

let isInitialised = false;

export type QuoteFormProps = {
  primaryColor?: string;
  secondaryColor?: string;
  darkColor?: string;
  fontFamily?: string;
  locale?: Locale;
  apiKey: string;
  networkId: string;
  apiDomain?: string;
  graphqlDomain?: string;
  redirectDomain?: string;
  parentNode?: HTMLDivElement & { _data: Quote };
  data?: string;
  autoRedirect?: "true" | "_blank" | "false";
  forceMobile?: boolean;
  persist?: boolean;
};

const QuoteForm = ({
  locale = defaultLocal,
  primaryColor = IDGPrimaryColor,
  secondaryColor = IDGSecondaryColor,
  darkColor = IDGDarkColor,
  fontFamily = IDGFontFamily,
  apiDomain = API_DOMAIN,
  graphqlDomain = GRAPHQL_DOMAIN,
  redirectDomain,
  apiKey,
  networkId,
  data,
  autoRedirect = "false",
  forceMobile = false,
  persist = false,
}: QuoteFormProps): VNode => {
  if (!isInitialised) {
    setAllCSSVariables(primaryColor, secondaryColor, darkColor, fontFamily);
    isInitialised = true;
  }

  const [persistedData] = useLocalStorage("quoteform-data", {});
  const propsData = data ? { ...JSON.parse(data) } : {};
  const mergedData = { ...propsData, ...persistedData };
  const quoteformRef = useRef<HTMLFormElement>();
  const formMethods = useForm<Quote>({
    mode: "onChange",
    defaultValues: defaultFormValues(
      locale,
      apiKey,
      networkId,
      persist ? JSON.stringify(mergedData) : data,
      apiDomain,
      graphqlDomain
    ),
  });

  const {
    field: { value: selectedInput, onChange: setSelectedInput },
  } = useController({ control: formMethods.control, name: "selectedInput" });

  useEffect(() => {
    if (data) {
      formMethods.reset(
        completedData({
          data,
          locale,
          apiKey,
          apiDomain,
          graphqlDomain,
          networkId,
        })
      );
    }
  }, [apiDomain, apiKey, data, formMethods, graphqlDomain, locale, networkId]);

  useEffect(() => {
    if (quoteformRef.current) {
      formMethods.setValue("quoteformRef", quoteformRef);
    }
  }, [formMethods, quoteformRef]);

  const services = formMethods.getValues("services");

  const isSubmitButtonDisabled = !services?.[0]?.id;

  const InputsContainer = useCallback(() => {
    const [language] = deconstructLocale(locale);
    return (
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <IntlProvider definition={language === "fr" ? fr : nl}>
            <div
              className={`flex flex-col gap-5 lg:gap-2 xl:gap-4 w-full max-w-[340px] rounded-idg lg:w-full lg:max-w-none z-40 mx-auto justify-evenly text-black font-primary ${
                forceMobile ? "lg:flex-col items-center" : "lg:flex-row"
              }`}
            >
              <VehicleContainer
                control={formMethods.control}
                selectedInput={selectedInput}
                setSelectedInput={setSelectedInput}
                forceMobile={forceMobile}
              />
              <LocationContainer
                control={formMethods.control}
                selectedInput={selectedInput}
                setSelectedInput={setSelectedInput}
                forceMobile={forceMobile}
              />
              <ServiceContainer
                control={formMethods.control}
                selectedInput={selectedInput}
                setSelectedInput={setSelectedInput}
                forceMobile={forceMobile}
              />
              <Button
                className={`
                  ${forceMobile ? "w-full lg:w-[300px]" : "lg:min-w-[200px]"}
                  min-h-[56px] h-full 
                  ${isSubmitButtonDisabled ? "" : "z-50 shadow-idgButton"}`}
                label={<Text id="submitButton" />}
                isDisabled={isSubmitButtonDisabled}
                isSubmit={true}
              />
              {!!selectedInput && (
                <Backdrop onClick={() => setSelectedInput(undefined)} />
              )}
            </div>
            {/* Fragment mandatory so that IntlProvider is working */}
            <></>
          </IntlProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    );
  }, [
    locale,
    forceMobile,
    formMethods.control,
    selectedInput,
    setSelectedInput,
    isSubmitButtonDisabled,
  ]);

  const handleSeePriceEvent = () => {
    sendDatalayerEvent("see_prices");
  };

  const onSubmit = (data: Quote) => {
    const url = generateRedirectionURL(data);
    handleSeePriceEvent();
    submitEvent(data, url);
    if (autoRedirect !== "false") {
      redirectToDevis({
        url,
        redirectDomain,
        autoRedirect,
        apiDomain,
        networkId,
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      {persist && (
        <LocalStoragePersistOption locale={locale} formMethods={formMethods} />
      )}
      <form
        className={`flex flex-col container gap-4 items-center mx-auto ${
          forceMobile ? "lg:flex-col" : "lg:flex-row"
        }`}
        data-testid="idg-quoteform"
        ref={quoteformRef}
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <InputsContainer />
      </form>
      <DebugFormData />
    </FormProvider>
  );
};

type PersistOptionProps = {
  formMethods: UseFormReturn<Quote>;
  locale: Locale;
};

const LocalStoragePersistOption = ({
  formMethods,
  locale,
}: PersistOptionProps) => {
  useFormPersist(`quoteform-data-${locale}`, {
    watch: formMethods.watch,
    setValue: formMethods.setValue,
    storage: window.localStorage,
    // todo: Remove extra properties
    // exclude: ["apiDomain", "apiKey", "locale", "graphqlDomain", "networkId"],
  });

  return <></>;
};

export default QuoteForm;
