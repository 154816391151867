import { IDGHeaders, Locale } from "types";
import { deconstructLocale } from "../../utils/locale";
import { CarService } from "./types";


export const getServices = (locale: Locale = 'fr-fr', headers: IDGHeaders, apiDomain: string): Promise<CarService[] | null> => {
    const endpoint = new URL(`${apiDomain}/carservices?_format=json`);
    const [language, country] = deconstructLocale(locale);
    endpoint.searchParams.append("countryCode", country);
    return fetch(endpoint, {
        headers: {
            ...headers,
            'x-network-id': "IDG",
            'Accept-Language': language,
        },
    })
        .then((response) => {
            if (response.status >= 400) {
                throw new Error("Error fetching data");
            } else {
                return response.json();
            }
        })
}