import "preact/debug";
import { h, VNode } from "preact";
import { useFormContext } from "react-hook-form";
import { DevTool } from "@hookform/devtools";

const DebugFormData = (): VNode => {
  const { control } = useFormContext();
  return <DevTool control={control} />;
};

export default DebugFormData;
