import { Locale, Quote } from "types";
import { isBE } from "./locale";

export const defaultFormValues = (
  locale: Locale,
  apiKey: string,
  networkId: string,
  data: string,
  apiDomain?: string,
  graphqlDomain?: string
): Quote => {
  if (data) {
    return {
      ...completedData({
        data,
        locale,
        apiKey,
        networkId,
        apiDomain,
        graphqlDomain,
      }),
    };
  }

  return {
    vehicle: {
      tabIndex: 0,
      model: {
        make: undefined,
        model: undefined,
        version: undefined,
      },
      identifier: {
        type: isBE(locale) ? "VIN" : "licensePlate",
        identifier: "",
        vehicle: undefined,
      },
    },
    location: {
      query: "",
    },
    selectedInput: undefined,
    locale,
    apiKey,
    apiDomain,
    graphqlDomain,
    networkId,
  };
};

type CompletedDataProps = {
  data: string;
  locale: Locale;
  apiKey: string;
  apiDomain: string;
  graphqlDomain: string;
  networkId: string;
};

export const completedData = ({
  data,
  locale,
  apiKey,
  apiDomain,
  graphqlDomain,
  networkId,
}: CompletedDataProps): Quote => {
  const parsedData: Quote = JSON.parse(data);
  return {
    ...parsedData,
    vehicle: {
      tabIndex: parsedData?.vehicle?.tabIndex ?? 0,
      model: {
        make: parsedData?.vehicle?.model?.make ?? undefined,
        model: parsedData?.vehicle?.model?.model ?? undefined,
        version: parsedData?.vehicle?.model?.version ?? undefined,
      },
      identifier: {
        type: !isBE(locale) ? "licensePlate" : "VIN",
        identifier: parsedData?.vehicle?.identifier?.identifier ?? "",
        vehicle: parsedData?.vehicle?.identifier?.vehicle,
      },
    },
    location: {
      query: parsedData?.location?.query ?? "",
      location: parsedData?.location?.location,
      userPosition: parsedData?.location?.userPosition,
    },
    services: parsedData?.services,
    selectedInput: undefined,
    locale,
    apiKey,
    apiDomain,
    graphqlDomain,
    networkId,
  };
};
