import { useCallback } from "preact/hooks";
import { h, VNode } from "preact";
import { Control, useWatch } from "react-hook-form";
import { ChevronDownIcon, PointerIcon } from "../../icons";
import { handleSelectInput } from "./vehicle-container";
import LocationAutocomplete from "../molecules/location/location-autocomplete";
import LocalisationButton from "../molecules/location/localisation-button";
import { InputTypes, Quote } from "types";
import { Text } from "preact-i18n";
import useServices from "../../hooks/apis/use-services";
import useLocationCallback from "../../hooks/use-location-callback";

type LocationContainerProps = {
  control: Control<Quote>;
  selectedInput: InputTypes | undefined;
  setSelectedInput: (...event: InputTypes[]) => void;
  forceMobile: boolean;
};

const defaultLabel = <Text id="location.defaultLabel" />;
const aroundMeLabel = <Text id="location.aroundMe" />;
const type: InputTypes = "location";
const LabelToDisplay = ({
  location,
  userPosition,
  isSelected,
}: {
  location: Quote["location"]["location"];
  userPosition: Quote["location"]["userPosition"]["latitude"];
  isSelected: boolean;
}): VNode => {
  if (!isSelected && location) {
    return (
      <div className="leading-none font-bold truncate">{location.label}</div>
    );
  }
  if (!isSelected && userPosition) {
    return <div className="leading-none font-bold">{aroundMeLabel}</div>;
  }
  return <div className="leading-none">{defaultLabel}</div>;
};

export const LocationContainer = ({
  control,
  selectedInput,
  setSelectedInput,
  forceMobile,
}: LocationContainerProps): VNode => {
  const [location, userPosition, vehicleIdentifier, vehicleVersion] = useWatch({
    control,
    name: [
      "location.location",
      "location.userPosition.latitude",
      "vehicle.identifier.vehicle",
      "vehicle.model.version",
    ],
  });
  useLocationCallback(control);
  useServices();

  const resetSelectedInput = useCallback(() => {
    setSelectedInput(undefined);
  }, [setSelectedInput]);

  const isSelected = selectedInput === type;
  const isDisabled = !(!!vehicleVersion || !!vehicleIdentifier);
  const isHidden = !!selectedInput && !isSelected && isDisabled;

  return (
    <div
      className={`h-full rounded-idg w-full lg:min-w-[300px] shrink relative ${
        isDisabled
          ? "cursor-not-allowed text-grey-soft"
          : "cursor-pointer shadow-idgButton"
      }
      ${isHidden ? "hidden lg:flex" : ""}
      ${isSelected ? "rounded-b-none z-50" : ""}`}
      data-testid="location-container"
    >
      <button
        className={`flex w-full h-full py-3 px-2 items-center justify-between bg-white ${
          isSelected ? "rounded-t-idg rounded-b-none" : "rounded-idg"
        }`}
        onClick={() => handleSelectInput(type, isSelected, setSelectedInput)}
        disabled={isDisabled}
        type="button"
      >
        <div className="flex w-11/12 h-full gap-3 items-center text-left">
          <PointerIcon color={isDisabled ? "#ADABAB" : undefined} />
          <LabelToDisplay
            location={location}
            userPosition={userPosition}
            isSelected={isSelected}
          />
        </div>
        <span className={`pr-2`}>
          {
            <ChevronDownIcon
              color={isDisabled ? "#ADABAB" : undefined}
              className={`${isSelected ? "rotate-180" : ""}`}
            />
          }
        </span>
      </button>
      {isDisabled && (
        <div className="absolute bg-dark opacity-[0.15] top-0 bottom-0 right-0 left-0 rounded-idg" />
      )}
      {isSelected && (
        <div className={`${forceMobile ? "" : "lg:absolute"} left-0 right-0`}>
          <div
            className={`flex px-4 py-5 gap-7 flex-col bg-white ${
              isSelected ? "rounded-b-idg" : ""
            }`}
          >
            <LocationAutocomplete resetSelectedInput={resetSelectedInput} />
            <LocalisationButton resetSelectedInput={resetSelectedInput} />
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationContainer;
