type CSSColorVariables = "primary" | "secondary" | "dark";

const r = document.querySelector(":root") as HTMLElement | null;

export const getCSSVariable = (variable: CSSColorVariables): string => {
  const rs = getComputedStyle(r);
  return rs.getPropertyValue(`--IDG${variable}`);
};
export const setCSSVariable = (
  variable: CSSColorVariables,
  value: string
): void => {
  return r?.style.setProperty(`--IDG${variable}`, value);
};

export const setAllCSSVariables = (
  primary: string,
  secondary: string,
  dark: string,
  fontFamily: string
): void => {
  if (r) {
    r.style.setProperty(`--IDGprimary`, primary);
    r.style.setProperty(`--IDGsecondary`, secondary);
    r.style.setProperty(`--IDGdark`, dark);
    r.style.setProperty(`--IDGfont`, fontFamily);
  }
};

export const Color = (color: CSSColorVariables): string => `var(--IDG${color})`;
export const twColor = (color: CSSColorVariables): string =>
  `[color:var(--IDG${color})]`;
