import { useCallback, useMemo } from "preact/hooks";
import { h, VNode } from "preact";
import { useFormContext, useFieldArray } from "react-hook-form";
import useServiceCategories from "../../../hooks/apis/use-service-categories";
import Button from "../../atoms/button";
import TagList from "../tag-list";
import FilteredSelect from "../filtered-select";
import DignosticModal from "./diagnostics-modal";
import useServices from "../../../hooks/apis/use-services";
import { Quote, SelectedCategory } from "types";
import { Text } from "preact-i18n";
import { scrollToTop } from "../../../utils/scroll-to";

export const maxNumberOfPrestations = 3;

const ServiceSelect = (): VNode => {
  const { control, getValues } = useFormContext<Quote>();
  const {
    fields: services,
    append,
    remove,
  } = useFieldArray<Quote>({
    control,
    name: "services",
  });

  const { data: servicesOptions, isLoading } = useServiceCategories();
  const quoteformRef = useMemo(() => getValues("quoteformRef"), [getValues]);
  const handleAddService = useCallback(
    (...event: SelectedCategory[]) => {
      const addedPrestation = event[0];
      const alreadyAddedPrestationIndex = services.findIndex(
        (service: SelectedCategory) => service.child.id === addedPrestation.id
      );
      const isAlreadyAdded = alreadyAddedPrestationIndex === -1;
      if (isAlreadyAdded) {
        append(addedPrestation);
        scrollToTop(quoteformRef);
      }
    },
    [append, quoteformRef, services]
  );

  const handleRemoveService = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  return (
    <div className={`flex flex-col w-full rounded-idg gap-4`}>
      {services?.length > 0 ? (
        <div className="flex flex-col px-4 gap-4">
          <TagList
            tags={services as SelectedCategory[]}
            handleRemove={handleRemoveService}
          />
          {services.length < maxNumberOfPrestations && (
            <Button
              className="min-w-[200px] h-14 lg:hidden"
              label={<Text id="submitButton" />}
              isSubmit={true}
            />
          )}
        </div>
      ) : (
        <DignosticModal
          onChange={handleAddService}
          options={servicesOptions || []}
        />
      )}

      {services.length < maxNumberOfPrestations && (
        <FilteredSelect
          value={services as SelectedCategory[]}
          onChange={handleAddService}
          handleRemoveValue={handleRemoveService}
          options={servicesOptions || []}
          isLoading={isLoading}
          withExtraInfo={true}
        />
      )}
    </div>
  );
};

export default ServiceSelect;
