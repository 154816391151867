import { useQuery } from "@tanstack/react-query";
import { UseFormSetValue, useFormContext, useWatch } from "react-hook-form";
import { Category, IDGHeaders, Option, Quote, SelectedModelVersion } from "types";
import { EnergyType, ModelsByMake } from "../../api/vehicle/types";
import { getMakes, getModels, getVersionEnergyType, getVersions } from "../../api/vehicle/vehicle-by-model";
import useApiHeaders from "./use-api-headers";
import { useGraphQLDomain } from "./use-domain";

const useVehicleModelOptions = (): {
    makes: Option[] | [];
    models: Category[] | [];
    versions: Option[] | [];
    isLoading: {
        makes: boolean,
        models: boolean,
        versions: boolean,
    };
} => {
    const { control, setValue } = useFormContext<Quote>();
    const vehicleModel = useWatch({
        control,
        name: "vehicle.model",
    });
    const headers = useApiHeaders()
    const graphqlDomain = useGraphQLDomain();
    const { makes, isLoading: isLoadingMakes } = useVehicleMake(headers, graphqlDomain)
    const { models, isLoading: isLoadingModels } = useVehicleModels(vehicleModel?.make?.id, headers, graphqlDomain)
    const { versions, isLoading: isLoadingVersions } = useVehicleVersions(vehicleModel?.make, vehicleModel?.model, headers, graphqlDomain)
    useVehicleEnergyType(vehicleModel?.version, setValue, headers, graphqlDomain)
    return {
        makes,
        models,
        versions,
        isLoading: {
            makes: isLoadingMakes,
            models: isLoadingModels,
            versions: isLoadingVersions,
        }
    }
}

const useVehicleMake = (headers: IDGHeaders, graphqlDomain: string): { makes: Option[] | [], isLoading: boolean } => {
    const { data: makes, isLoading } = useQuery(
        ["vehicleModelMake"],
        () => getMakes(headers, graphqlDomain)
    );
    return { makes, isLoading };
}
const transformModelsToCategory = (models: ModelsByMake[] | []): Category[] => {
    return models?.length ? models.map((model: ModelsByMake) => ({
        id: model.id,
        label: model.label,
        children: [...(model.version)]
    })) : []
}
const useVehicleModels = (make: string, headers: IDGHeaders, graphqlDomain: string): { models: Category[] | [], isLoading: boolean } => {
    const { data: models, isLoading } = useQuery(
        ["vehicleModelModels", make],
        () => getModels(make, headers, graphqlDomain),
        {
            enabled: !!make,
        }
    );

    return { models: transformModelsToCategory(models), isLoading };
}
const useVehicleVersions = (make: Option, model: SelectedModelVersion, headers: IDGHeaders, graphqlDomain: string): { versions: Option[] | [], isLoading: boolean } => {
    const { data: versions, isLoading } = useQuery(
        ["vehicleVersions", make?.id, model?.id, model?.child?.id],
        () => getVersions(make?.id, model?.id, model?.child?.id, headers, graphqlDomain),
        {
            enabled: !!make?.id && !!model?.id && !!model?.child?.id,
        }
    );
    return { versions, isLoading };
}

const useVehicleEnergyType = (version: Quote["vehicle"]["model"]["version"], setValue: UseFormSetValue<Quote>, headers: IDGHeaders, graphqlDomain: string): { energyType: EnergyType | undefined, isLoading: boolean } => {
    const { data: energyType, isLoading } = useQuery(
        ["vehicleEnergyTypes", version?.id],
        () => getVersionEnergyType(version?.id, headers, graphqlDomain),
        {
            enabled: !!version?.id,
        }
    );
    if (energyType && energyType !== version?.energyType) {
        setValue('vehicle.model.version.energyType', energyType);
    }
    return { energyType, isLoading };
}





export default useVehicleModelOptions;