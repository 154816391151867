import { useAPIDomain } from './use-domain';
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { LocationSuggestion } from "../../api/location/types";
import getLocationSuggestions from "../../api/location/get-location-suggestions";
import useApiHeaders from "./use-api-headers";
import useLocale from "./use-locale";

const useLocationSuggestions = (
  debouncedQuery: string
): { suggestions?: LocationSuggestion[] } & UseQueryResult<
  [] | LocationSuggestion[],
  unknown
> => {
  const locale = useLocale();
  const headers = useApiHeaders();
  const apiDomain = useAPIDomain();
  const queryResults = useQuery(
    ["location-suggestions", debouncedQuery],
    () => getLocationSuggestions(debouncedQuery, locale, headers, apiDomain),
    {
      enabled: debouncedQuery?.length > 2,
    }
  );

  const suggestions = debouncedQuery.length < 3 ? undefined : queryResults?.data;

  return { ...queryResults, suggestions };
};
export default useLocationSuggestions;
