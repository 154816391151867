import { Locale } from "types";
import { ServiceCategory } from "../../service/types";

import MockedServicesFR from "./fr.json"
import MockedServicesNL from "./nl.json"


export const getMockedServiceCategories = (locale: Locale): ServiceCategory[] => {
    if (locale === "nl-be") {
        return MockedServicesNL as ServiceCategory[];
    }
    return MockedServicesFR as ServiceCategory[];
}
