import { useState, useEffect } from "preact/hooks";
import { h, VNode } from "preact";
import { VehicleIdentifierType } from "types";

interface VehicleIdentifierPlaceholderProps {
  identifierType: VehicleIdentifierType;
  identifier: string;
}

export const initialPlaceholder = {
  VIN: "XXXXXXXXXXXXXXXXX",
  oldLicensePlate: "XXXXXXXX",
  licensePlate: "XXXXXXX",
};
export const placeholderToDisplay = {
  oldLicensePlate: "123ABC45",
  licensePlate: "AB123CD",
};

const computeUpdatedPlaceHolder = ({
  identifier,
  currentPlaceholder,
  placeholderType,
}: {
  identifier: string;
  currentPlaceholder: string;
  placeholderType: VehicleIdentifierType;
}): string => {
  if (identifier.length === 0) {
    return initialPlaceholder[placeholderType];
  }
  if (identifier.length === 1) {
    currentPlaceholder = initialPlaceholder[placeholderType];
  }

  const maxLength = initialPlaceholder[placeholderType].length;

  if (identifier.length >= maxLength) {
    const emptyPlaceholder = initialPlaceholder[placeholderType].replaceAll(
      "X",
      " "
    );
    return emptyPlaceholder;
  }
  if (identifier.length > maxLength) {
    return currentPlaceholder;
  }

  const newPlaceholder = Object.assign([], currentPlaceholder);
  const numberOfX = newPlaceholder.filter((c) => c === "X").length;
  const isRemovingChar =
    currentPlaceholder.length - numberOfX > identifier.length;
  if (isRemovingChar) {
    newPlaceholder[identifier.length] = "X";
  } else {
    newPlaceholder[identifier.length - 1] = " ";
  }
  return newPlaceholder.join("");
};

const formatPlaceholder = (
  placeholder: string,
  identifierType: VehicleIdentifierType
): string => {
  const placeholderArray = Object.assign([], placeholder);
  const formatedPlaceholder = placeholderArray.map((char, index) => {
    if (char === " ") return " ";
    return placeholderToDisplay[identifierType][index];
  });
  return formatedPlaceholder.join("");
};

const VehicleIdentifierPlaceholder = ({
  identifier,
  identifierType,
}: VehicleIdentifierPlaceholderProps): VNode => {
  const [placeholder, setPlaceholder] = useState(
    computeUpdatedPlaceHolder({
      identifier: identifier.replaceAll(/-|\s/g, ""),
      currentPlaceholder: initialPlaceholder[identifierType],
      placeholderType: identifierType,
    })
  );

  useEffect(() => {
    setPlaceholder((currentPlaceholder) =>
      computeUpdatedPlaceHolder({
        identifier: identifier.replaceAll(/-|\s/g, ""),
        currentPlaceholder,
        placeholderType: identifierType,
      })
    );
  }, [identifier, identifierType, setPlaceholder]);

  if (identifierType === "VIN") {
    return (
      <p className="text-grey-light w-full py-2 absolute z-0 top-0 whitespace-pre tracking-[1.6px]">
        {placeholder}
      </p>
    );
  }
  const placeholderToDisplay = formatPlaceholder(placeholder, identifierType);
  if (identifierType === "oldLicensePlate") {
    const firstWhitespaceIndex = identifier.indexOf(" ");
    const secondWhitespaceIndex = identifier.indexOf(
      " ",
      firstWhitespaceIndex + 1
    );

    let newPlaceholder = initialPlaceholder["oldLicensePlate"];
    if (identifier.match(/(^[0-9]{2,4}[A-z]{1,3}[0-9]{2}$)/)) {
      newPlaceholder = placeholder.replaceAll("X", " ");
    } else if (firstWhitespaceIndex === -1) {
      newPlaceholder = `${placeholderToDisplay.slice(
        0,
        3
      )} ${placeholderToDisplay.slice(3, 6)} ${placeholderToDisplay.slice(6)}`;
    } else if (firstWhitespaceIndex > 0 && secondWhitespaceIndex === -1) {
      newPlaceholder = `${placeholderToDisplay.slice(
        0,
        firstWhitespaceIndex
      )} ${placeholderToDisplay.slice(
        firstWhitespaceIndex,
        6
      )} ${placeholderToDisplay.slice(6)}`;
    } else {
      newPlaceholder = `${placeholderToDisplay.slice(
        0,
        firstWhitespaceIndex - 1
      )} ${placeholderToDisplay.slice(
        firstWhitespaceIndex - 1,
        secondWhitespaceIndex - 1
      )} ${placeholderToDisplay.slice(placeholderToDisplay.length - 2)}`;
    }

    return (
      <p className="text-grey-light w-full py-2 absolute z-0 top-0 whitespace-pre tracking-[1.6px]">
        {newPlaceholder}
      </p>
    );
  }

  return (
    <p className="text-grey-light w-full py-2 absolute z-0 top-0 whitespace-pre tracking-[1.6px]">
      {`${placeholderToDisplay.slice(0, 2)}${
        identifier.length < 2 ? "-" : " "
      }${placeholderToDisplay.slice(2, 5)}${
        identifier.length < 6 ? "-" : " "
      }${placeholderToDisplay.slice(5)}`}
    </p>
  );
};

export default VehicleIdentifierPlaceholder;
