import { h, VNode } from "preact";
import { Text } from "preact-i18n";
import { Category, Option, SelectedCategory } from "types";
import useModal from "../../../hooks/use-modal";
import Button from "../../atoms/button";
import Modal from "../../atoms/modal";
import Select from "../../atoms/select";

const diagnosticGammeId = "96";
const diagnosticGammeLabel = "DIAGNOSTICS GRATUITS";

const getDiagnostics = (options: Category[]): Option[] => {
  const diagnosticCategory = options.filter(
    (option) => option.id === diagnosticGammeId
  );
  return categoriesChildrenToOptionList(diagnosticCategory);
};

interface DiagnosticModalProps {
  options: Category[];
  onChange: (...event: SelectedCategory[]) => void;
}

const DignosticModal = ({ onChange, options }: DiagnosticModalProps): VNode => {
  const { isOpen, closeModal, openModal } = useModal();
  const diagnostics = getDiagnostics(options);

  const handleDiagnosticSelect = (...event: Option[]) => {
    const selectedDiagnostic = event[0];
    onChange({
      id: diagnosticGammeId,
      label: diagnosticGammeLabel,
      child: selectedDiagnostic,
    });
    closeModal();
  };

  return (
    <div className="px-4">
      <Button
        isLink={true}
        label={<Text id="service.helpButton" />}
        onClick={openModal}
      />
      <Modal
        isOpen={isOpen}
        title={<Text id="service.helpModalTitle" />}
        closeModal={closeModal}
      >
        <div className="flex flex-col pt-8 pb-4">
          <span>
            <Text id="service.helpModalIntro" />
          </span>
          <div className="-mx-8 pt-4">
            <Select
              onChange={handleDiagnosticSelect}
              options={diagnostics as Option[]}
              withExtraInfo={true}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DignosticModal;

export const categoriesChildrenToOptionList = (
  categories: Category[]
): Option[] => {
  return categories.reduce(
    (transformedOptions: SelectedCategory[], category: Category) => [
      ...transformedOptions,
      ...category.children,
    ],
    []
  );
};
