import { h, VNode } from "preact";

interface ButtonProps {
  label: string | JSX.Element;
  className?: string;
  isDisabled?: boolean;
  isSubmit?: boolean;
  isLink?: boolean;
  onClick?: () => void;
}

const Button = ({
  isDisabled,
  className,
  label,
  isSubmit,
  isLink,
  onClick,
}: ButtonProps): VNode => {
  if (isLink) {
    return (
      <button
        type={isSubmit ? "submit" : "button"}
        className={`underline text-left ${className}`}
        onClick={onClick}
        disabled={isDisabled}
      >
        {label}
      </button>
    );
  }
  return (
    <button
      type={isSubmit ? "submit" : "button"}
      disabled={isDisabled}
      onClick={onClick}
      className={`flex relative rounded-idg bg-secondary text-white justify-center p-4 items-center gap-4 uppercase  font-bold ${
        isDisabled ? "cursor-not-allowed" : "cursor-pointer"
      } ${className}`}
    >
      {label}
      {isDisabled && (
        <div className="absolute bg-dark opacity-[0.15] top-0 bottom-0 right-0 left-0 rounded-idg" />
      )}
    </button>
  );
};

export default Button;
