import { h, VNode } from "preact";
import { Control, useWatch } from "react-hook-form";
import { ChevronDownIcon, ToolsIcon } from "../../icons";
import { handleSelectInput } from "./vehicle-container";
import ServiceSelect, {
  maxNumberOfPrestations,
} from "../molecules/service/service-select";
import { InputTypes, Quote } from "types";
import { Text } from "preact-i18n";
import useServiceCallback from "../../hooks/use-service-callback";

type ServiceContainerProps = {
  control: Control<Quote>;
  selectedInput: InputTypes | undefined;
  setSelectedInput: (...event: InputTypes[]) => void;
  forceMobile: boolean;
};

const defaultLabel = <Text id="service.defaultLabel" />;
const type: InputTypes = "service";
const LabelToDisplay = ({ control }: { control: Control<Quote> }): VNode => {
  const services = useWatch({ control, name: "services" });
  if (services?.length) {
    return (
      <div className="leading-none font-bold">
        {`${services.length}${
          services.length === maxNumberOfPrestations
            ? `/${maxNumberOfPrestations}`
            : ""
        } `}{" "}
        <Text id="service.selectedPrestation" plural={services.length} />{" "}
      </div>
    );
  }
  return <div className="leading-none">{defaultLabel}</div>;
};

export const ServiceContainer = ({
  control,
  selectedInput,
  setSelectedInput,
  forceMobile,
}: ServiceContainerProps): VNode => {
  const [location, userLatitude, vehicleId, vehicleVersionId] = useWatch<Quote>(
    {
      control,
      name: [
        "location.location",
        "location.userPosition.latitude",
        "vehicle.identifier.vehicle.id",
        "vehicle.model.version.id",
      ],
    }
  );
  useServiceCallback(control);

  const isSelected = selectedInput === type;
  const isDisabled =
    !(location || !!userLatitude) || !(vehicleId || vehicleVersionId);
  const isHidden = !!selectedInput && !isSelected && isDisabled;

  return (
    <div
      className={`h-full rounded-idg w-full lg:min-w-[300px] shrink relative ${
        isDisabled
          ? "cursor-not-allowed text-grey-soft"
          : "cursor-pointer shadow-idgButton"
      }
      ${isHidden ? "hidden lg:flex" : ""}
      ${isSelected ? "rounded-b-none z-50" : ""}`}
      data-testid="service-container"
    >
      <button
        className={`flex w-full py-3 px-2 justify-between items-center bg-white ${
          isSelected ? "rounded-t-idg rounded-b-none" : "rounded-idg"
        }`}
        onClick={() => handleSelectInput(type, isSelected, setSelectedInput)}
        disabled={isDisabled}
        type="button"
      >
        <div className="flex w-11/12 gap-3 items-center text-left">
          <ToolsIcon color={isDisabled ? "#ADABAB" : undefined} />
          <LabelToDisplay control={control} />
        </div>
        <span className={`pr-2`}>
          {
            <ChevronDownIcon
              color={isDisabled ? "#ADABAB" : undefined}
              className={`${isSelected ? "rotate-180" : ""}`}
            />
          }
        </span>
      </button>

      {isDisabled && (
        <div className="absolute bg-dark opacity-[0.15] top-0 bottom-0 right-0 left-0 rounded-idg" />
      )}
      {isSelected && (
        <div
          className={`${
            forceMobile ? "rounded-b-idg" : "lg:absolute"
          } left-0 right-0`}
        >
          <div className="flex py-2 flex-col rounded-b-idg bg-white">
            <ServiceSelect />
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceContainer;
