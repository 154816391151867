import { h, Fragment, VNode } from "preact";
import { useCallback, useState } from "preact/hooks";
import { Listbox, Disclosure } from "@headlessui/react";
import { ChevronDownIcon, CrossIcon, InformationIcon } from "../../icons";
import InformationServiceModal from "./service/information-service-modal";
import { Category, SelectedCategory } from "types";

interface SelectProps {
  categories: Category[];
  values: SelectedCategory | SelectedCategory[];
  onChange: (...event: SelectedCategory[]) => void;
  handleRemoveValue?: (index: number) => void;
  defaultOpen: boolean;
  withExtraInfo?: boolean;
}

const SelectWithDisclosure = (props: SelectProps): VNode => {
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const {
    categories,
    values,
    onChange,
    defaultOpen,
    handleRemoveValue,
    withExtraInfo,
  } = props;

  const handleInformationClick = useCallback(
    (event: MouseEvent, selectedCategory: SelectedCategory) => {
      event.preventDefault();
      setSelectedCategory(selectedCategory);
    },
    [setSelectedCategory]
  );
  const handleInformationModalClose = useCallback(() => {
    setSelectedCategory(undefined);
  }, [setSelectedCategory]);

  const isCategorySelected = useCallback(
    (category: Category): boolean => {
      if (Array.isArray(values)) {
        return !!values?.find((value) => value?.label === category?.label);
      }
      return values?.label === category.label;
    },
    [values]
  );

  const isChildSelected = useCallback(
    (child: SelectedCategory["child"]): [boolean, number | undefined] => {
      if (Array.isArray(values)) {
        const index = values?.findIndex(
          (value) => value?.child.label === child?.label
        );
        return [index > -1, index];
      }
      const isSelected =
        (values as SelectedCategory)?.child.label === child?.label;
      return [isSelected, undefined];
    },
    [values]
  );

  const handleUpdate = useCallback(
    (selected: SelectedCategory): void => {
      const [isAlreadyAdded] = isChildSelected(selected.child);
      if (!isAlreadyAdded) onChange(selected);
    },
    [onChange, isChildSelected]
  );

  const handleDelete = useCallback(
    (e, index: number): void => {
      e.preventDefault();
      handleRemoveValue(index);
    },
    [handleRemoveValue]
  );
  return (
    <>
      <Listbox value={values} onChange={handleUpdate}>
        <Listbox.Options static>
          {categories.map((category: Category) => {
            if (!category?.children?.length) return;
            return (
              <div key={`${category.id}${Math.random()}`}>
                <Disclosure
                  defaultOpen={defaultOpen || isCategorySelected(category)}
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={`w-full py-2 px-4 h-11 flex items-center ${
                          open ? "font-bold" : ""
                        }`}
                      >
                        <div className="flex justify-between w-full items-center text-left">
                          <span>{category.label}</span>
                          <div className={open ? "rotate-180" : ""}>
                            <ChevronDownIcon />
                          </div>
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className="overflow-x-hidden border-t border-b border-x-grey-light border-opacity-25">
                        <div className="shadow-idgInner -mx-5 py-2">
                          <div className="mx-5 flex flex-col gap-4">
                            {category.children.map((child) => {
                              const [isSelected, selectedIndex] =
                                isChildSelected(child);
                              return (
                                <Listbox.Option
                                  key={child.id}
                                  data-cs-override-id={child.id}
                                  value={{
                                    id: category.id,
                                    label: category.label,
                                    child,
                                  }}
                                  className={({ active }) =>
                                    `min-h-[40px] flex gap-3 mx-2 px-2 rounded-lg items-center relative select-none py-1 pr-2 cursor-pointer hover:bg-dark hover:text-white ${
                                      active ? `text-dark` : ""
                                    } ${isSelected ? "font-bold" : ""}`
                                  }
                                >
                                  <>
                                    {withExtraInfo && (
                                      <div
                                        className="shrink-0"
                                        onClick={(e) =>
                                          handleInformationClick(
                                            e as unknown as MouseEvent,
                                            {
                                              id: category.id,
                                              label: category.label,
                                              child,
                                            }
                                          )
                                        }
                                      >
                                        <InformationIcon />
                                      </div>
                                    )}
                                    <span className="max-w-[85%]">
                                      {child.label}
                                    </span>

                                    {isSelected &&
                                      selectedIndex !== undefined && (
                                        <div
                                          className="shrink-0 absolute right-2"
                                          onClick={(e) =>
                                            handleDelete(e, selectedIndex)
                                          }
                                        >
                                          <CrossIcon color={"red"} />
                                        </div>
                                      )}
                                  </>
                                </Listbox.Option>
                              );
                            })}
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            );
          })}
        </Listbox.Options>
      </Listbox>
      {selectedCategory && (
        <InformationServiceModal
          selectedService={selectedCategory}
          closeModal={handleInformationModalClose}
          onChange={handleUpdate}
        />
      )}
    </>
  );
};

export default SelectWithDisclosure;
