import { useCallback, useState } from 'preact/hooks';


const useModal = (): { isOpen: boolean; closeModal: () => void; openModal: () => void; } => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen])
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen])



    return { isOpen, closeModal, openModal };
}


export default useModal;