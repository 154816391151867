import { IDGHeaders, Locale } from "types";
import { deconstructLocale } from "../../utils/locale";
import { LocationSuggestion } from "./types";

const getLocationSuggestions = (query: string, locale: Locale, headers: IDGHeaders, apiDomain: string): Promise<LocationSuggestion[] | []> => {
    const endpoint = new URL(`${apiDomain}/locationsv2`);
    const [language, country] = deconstructLocale(locale)
    endpoint.searchParams.append("query", query);
    endpoint.searchParams.append("country_code", country);
    endpoint.searchParams.append("language", language);

    return fetch(endpoint, {
        headers
    })
        .then((response) => {
            if (response.status >= 400) {
                throw new Error("Error fetching data");
            } else {
                return response.json();
            }
        })
        .then((data) => data.data);
}


export default getLocationSuggestions;