import { h, VNode } from "preact";
import { Color } from "../utils/css-variables";

interface IconProps {
  color?: string;
  className?: string;
}

export const CarIcon = ({ color }: IconProps): VNode => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="shrink-0"
  >
    <path
      d="M2.35913 15H30.3591"
      stroke={color ? color : Color("primary")}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.3591 23V26C28.3591 26.2652 28.2538 26.5196 28.0662 26.7071C27.8787 26.8946 27.6243 27 27.3591 27H24.3591C24.0939 27 23.8396 26.8946 23.652 26.7071C23.4645 26.5196 23.3591 26.2652 23.3591 26V23"
      stroke={color ? color : Color("primary")}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.35913 23V26C9.35913 26.2652 9.25377 26.5196 9.06624 26.7071C8.8787 26.8946 8.62435 27 8.35913 27H5.35913C5.09391 27 4.83956 26.8946 4.65202 26.7071C4.46449 26.5196 4.35913 26.2652 4.35913 26V23"
      stroke={color ? color : Color("primary")}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.35913 19H10.3591"
      stroke={color ? color : Color("primary")}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.3591 19H24.3591"
      stroke={color ? color : Color("primary")}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.3591 15L24.6216 6.6C24.5441 6.42229 24.4165 6.27096 24.2545 6.16444C24.0925 6.05791 23.903 6.00078 23.7091 6H9.00913C8.81524 6.00078 8.62575 6.05791 8.46374 6.16444C8.30173 6.27096 8.17419 6.42229 8.09663 6.6L4.35913 15V23H28.3591V15Z"
      stroke={color ? color : Color("primary")}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const PointerIcon = ({ color }: IconProps): VNode => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="shrink-0"
  >
    <path
      d="M7.01929 29H25.0193"
      stroke={color ? color : Color("primary")}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.0193 17C18.2284 17 20.0193 15.2091 20.0193 13C20.0193 10.7909 18.2284 9 16.0193 9C13.8101 9 12.0193 10.7909 12.0193 13C12.0193 15.2091 13.8101 17 16.0193 17Z"
      stroke={color ? color : Color("primary")}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.0193 13C26.0193 22 16.0193 29 16.0193 29C16.0193 29 6.01929 22 6.01929 13C6.01929 10.3478 7.07286 7.8043 8.94822 5.92893C10.8236 4.05357 13.3671 3 16.0193 3C18.6715 3 21.215 4.05357 23.0904 5.92893C24.9657 7.8043 26.0193 10.3478 26.0193 13V13Z"
      stroke={color ? color : Color("primary")}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ToolsIcon = ({ color }: IconProps): VNode => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="shrink-0"
  >
    <path
      d="M28.2787 8.87498C28.9347 10.4066 29.0952 12.105 28.7379 13.7324C28.3807 15.3598 27.5234 16.8347 26.2862 17.9507C25.049 19.0667 23.4937 19.7678 21.8382 19.956C20.1827 20.1441 18.5098 19.8099 17.0537 19L10.0412 27.125C9.47763 27.6886 8.71324 28.0052 7.91621 28.0052C7.11918 28.0052 6.3548 27.6886 5.79121 27.125C5.22763 26.5614 4.91101 25.797 4.91101 25C4.91101 24.203 5.22763 23.4386 5.79121 22.875L13.9162 15.8625C13.1063 14.4064 12.7721 12.7335 12.9602 11.078C13.1484 9.42247 13.8495 7.86723 14.9655 6.63001C16.0814 5.3928 17.5564 4.53553 19.1838 4.17824C20.8112 3.82095 22.5096 3.98153 24.0412 4.63748L18.7912 9.87498L19.5037 13.4125L23.0412 14.125L28.2787 8.87498Z"
      stroke={color ? color : Color("primary")}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const SearchIcon = ({ color }: IconProps): VNode => (
  <svg
    className="h-6 shrink-0"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="1.76471"
      height="7.05882"
      rx="0.882353"
      transform="matrix(0.716719 -0.697362 0.716719 0.697362 9.5564 10.6829)"
      fill={color ? color : Color("primary")}
    />
    <ellipse
      cx="6.45932"
      cy="6.47745"
      rx="4.98764"
      ry="4.85294"
      stroke={color ? color : Color("primary")}
      stroke-width="1.5"
    />
  </svg>
);

export const ChevronDownIcon = ({ color, className }: IconProps): VNode => (
  <svg
    className={`${className} shrink-0`}
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="chevron-down-svg"
  >
    <path
      d="M1.2854 0.756104L8.7854 8.2561L16.2854 0.756104"
      stroke={color ? color : Color("secondary")}
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);

export const LoadingIcon = (): VNode => (
  <svg
    className={`animate-spin -ml-1 mr-3 h-5 w-5 text-primary shrink-0`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      stroke-width="4"
    />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

export const CrossIcon = ({ color }: IconProps): VNode => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="shrink-0"
  >
    <g clip-path="url(#clip0_19_385)">
      <path
        d="M14.9045 1.75L2.04736 13.75"
        stroke={color ? color : Color("primary")}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.04736 1.75L14.9045 13.75"
        stroke={color ? color : Color("primary")}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_19_385">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(0.975952 0.25)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const LocationIcon = ({ color }: IconProps): VNode => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="shrink-0"
  >
    <path
      d="M10.2617 17.5742C14.0587 17.5742 17.1367 14.4962 17.1367 10.6992C17.1367 6.90226 14.0587 3.82422 10.2617 3.82422C6.46476 3.82422 3.38672 6.90226 3.38672 10.6992C3.38672 14.4962 6.46476 17.5742 10.2617 17.5742Z"
      stroke={color ? color : Color("primary")}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.2617 2.26172V5.38672"
      stroke={color ? color : Color("primary")}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.82422 10.6992H4.94922"
      stroke={color ? color : Color("primary")}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.2617 19.1367V16.0117"
      stroke={color ? color : Color("primary")}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.6992 10.6992H15.5742"
      stroke={color ? color : Color("primary")}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.2617 13.1992C11.6424 13.1992 12.7617 12.0799 12.7617 10.6992C12.7617 9.31851 11.6424 8.19922 10.2617 8.19922C8.88101 8.19922 7.76172 9.31851 7.76172 10.6992C7.76172 12.0799 8.88101 13.1992 10.2617 13.1992Z"
      stroke={color ? color : Color("secondary")}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const InformationIcon = (): VNode => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="shrink-0"
  >
    <g clip-path="url(#clip0_3532_27623)">
      <path
        d="M16.2148 8.521C16.2148 12.5251 12.9689 15.771 8.96484 15.771C4.96078 15.771 1.71484 12.5251 1.71484 8.521C1.71484 4.51693 4.96078 1.271 8.96484 1.271C12.9689 1.271 16.2148 4.51693 16.2148 8.521Z"
        fill="#00A9CD"
        stroke="#00A9CD"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.96094 6.67529C9.51322 6.67529 9.96094 7.12301 9.96094 7.67529V11.9565C9.96094 12.5088 9.51322 12.9565 8.96094 12.9565C8.40865 12.9565 7.96094 12.5088 7.96094 11.9565V7.67529C7.96094 7.12301 8.40865 6.67529 8.96094 6.67529Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.96094 4.12549C7.96094 3.5732 8.40865 3.12549 8.96094 3.12549H8.96472C9.51701 3.12549 9.96472 3.5732 9.96472 4.12549C9.96472 4.67777 9.51701 5.12549 8.96472 5.12549H8.96094C8.40865 5.12549 7.96094 4.67777 7.96094 4.12549Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3532_27623">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.964844 0.520996)"
        />
      </clipPath>
    </defs>
  </svg>
);
