import { useCallback, useEffect } from "preact/hooks";
import { Control, useFieldArray, useFormContext } from "react-hook-form";
import { attachCallback } from "../utils/callbacks";
import { Quote } from "types";
import { getServiceById } from "./apis/use-service-categories";

export default function useServiceCallback(control: Control<Quote>): void {
  const { getValues } = useFormContext<Quote>();
  const { replace: replaceService } = useFieldArray<Quote>({
    control,
    name: "services",
  });
  const locale = getValues("locale");

  const setServiceById = useCallback(
    (serviceId: string) => {
      const serviceToAdd = getServiceById(serviceId, locale);
      if (serviceToAdd) {
        const currentServices = getValues("services");
        const isAlreadyAdded = currentServices.find(
          (service) => service.id === serviceToAdd.id
        );
        if (isAlreadyAdded) {
          return;
        }
        replaceService(serviceToAdd);
      }
    },
    [locale, getValues, replaceService]
  );

  useEffect(
    () => attachCallback({ key: "setServiceById", callback: setServiceById }),
    [setServiceById]
  );
}
