import { h, VNode } from "preact";
import { Tab } from "@headlessui/react";
import { useFormContext, useController } from "react-hook-form";
import VehicleIdentifier from "./vehicle-identifier";
import VehiculeModelSelect from "./vehicle-model-select";
import useVehicleModel from "../../../hooks/apis/use-vehicle-model";
import { Option, Quote } from "types";
import { Text } from "preact-i18n";

export type vehiculeModelType = {
  make: Option | undefined;
  model: Option | undefined;
  version: Option | undefined;
};

const VehicleTabs = (): VNode => {
  const { getValues, control } = useFormContext<Quote>();
  useVehicleModel();
  const {
    field: { value: selectedIndex, onChange: setSelectedIndex },
  } = useController({ control, name: "vehicle.tabIndex" });
  const locale = getValues("locale");
  return (
    <div className={`flex w-full rounded-b-idg bg-white items-center flex-col`}>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="flex w-full space-x-1">
          <Tab
            className={({ selected }: { selected: boolean }) =>
              `w-full  py-1 ring-white underline ${
                selected ? "text-secondary font-bold" : "text-black"
              }`
            }
          >
            {locale === "fr-fr" ? "Immatriculation" : "VIN"}
          </Tab>
          <Tab
            className={({ selected }: { selected: boolean }) =>
              `w-full  py-1 ring-white underline  ${
                selected ? `text-secondary font-bold` : "text-black"
              }`
            }
          >
            <Text id="vehicle.model" />
          </Tab>
        </Tab.List>
        <Tab.Panels className="w-full py-5">
          <Tab.Panel
            key={`Immatriculation-panel`}
            className="rounded-xl bg-white"
          >
            <VehicleIdentifier />
          </Tab.Panel>
          <Tab.Panel key={`Modèle-panel`} className="rounded-xl bg-white">
            <VehiculeModelSelect />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default VehicleTabs;
