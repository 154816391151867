import { h, VNode, Fragment } from "preact";
import { useState } from "preact/hooks";
import { Listbox } from "@headlessui/react";
import useMountedState from "../../hooks/use-monted-state";
import InformationServiceModal from "../molecules/service/information-service-modal";
import { InformationIcon } from "../../icons/index";
import { Option } from "types";

interface SelectProps {
  options: Option[];
  value?: Option;
  onChange: (...event: Option[]) => void;
  withExtraInfo?: boolean;
}

const Select = (props: SelectProps): VNode => {
  const isMounted = useMountedState();
  const [InformationOption, setInformationOption] = useState(undefined);

  const handleInformationClick = (
    event: MouseEvent,
    InformationOption: Option
  ) => {
    event.preventDefault();
    setInformationOption(InformationOption);
  };
  const handleInformationModalClose = () => {
    setInformationOption(undefined);
  };

  const handleUpdate = (value): void => {
    if (isMounted) onChange(value);
  };

  const { options, value, onChange, withExtraInfo } = props;
  return (
    <>
      <Listbox value={value} onChange={onChange}>
        <Listbox.Options static>
          {options.map((option: Option) => {
            const isSelected = option.id === value?.id;
            return (
              <Listbox.Option
                key={option.id}
                value={option}
                className={({ active }) =>
                  `relative h-11 flex items-center select-none py-1 mx-4 pr-2 gap-4 cursor-pointer rounded-lg hover:bg-dark hover:text-white pl-4 ${
                    active ? "text-dark" : ""
                  } ${isSelected ? "font-bold" : ""}`
                }
              >
                <>
                  {withExtraInfo && (
                    <div
                      className="shrink-0"
                      onClick={(e) =>
                        handleInformationClick(
                          e as unknown as MouseEvent,
                          option
                        )
                      }
                    >
                      <InformationIcon />
                    </div>
                  )}
                  {option.label}
                </>
              </Listbox.Option>
            );
          })}
        </Listbox.Options>
      </Listbox>
      {InformationOption && (
        <InformationServiceModal
          selectedService={InformationOption}
          closeModal={handleInformationModalClose}
          onChange={handleUpdate}
        />
      )}
    </>
  );
};

export default Select;
