import { useFormContext } from "react-hook-form";
import { Quote } from "types";

export const useAPIDomain = (): string => {
    const { getValues } = useFormContext<Quote>();
    return getValues('apiDomain');
}
export const useGraphQLDomain = (): string => {
    const { getValues } = useFormContext<Quote>();
    return getValues('graphqlDomain');
}
