import { h, VNode } from "preact";
import { withText } from "preact-i18n";
import { useController, useFormContext } from "react-hook-form";
import { Quote } from "types";
import { LocationIcon } from "../../../icons";
import Button from "../../atoms/button";

const handleGetUserPosition = (
  setUserPosition: {
    (...event: { latitude: number; longitude: number }[]): void;
  },
  resetSelectedInput: () => void
): void => {
  return navigator.geolocation.getCurrentPosition((position) => {
    const { latitude, longitude } = position.coords;
    setUserPosition({ latitude, longitude });
    resetSelectedInput();
  });
};

type LocalisationButtonProps = {
  resetSelectedInput: () => void;
  searchAroundMe: string;
};

const LocalisationButton = ({
  resetSelectedInput,
  searchAroundMe,
}: LocalisationButtonProps): VNode => {
  const { control, setValue } = useFormContext<Quote>();
  const {
    field: { onChange: setUserPosition },
  } = useController<Quote>({ control, name: "location.userPosition" });
  const handleClick = () => {
    handleGetUserPosition(setUserPosition, resetSelectedInput);
    setValue("location.location", undefined);
  };
  return (
    <div className="flex gap-2">
      <LocationIcon />
      <Button label={searchAroundMe} isLink onClick={handleClick} />
    </div>
  );
};

export default withText("location.searchAroundMe")(LocalisationButton);
