import { h, VNode } from "preact";

interface BackdropProps {
  onClick: () => void;
}

const Backdrop = ({ onClick }: BackdropProps): VNode => (
  <div
    onClick={onClick}
    className="fixed inset-0 bg-black opacity-50 z-40"
    data-testid="backdrop"
  />
);

export default Backdrop;
