const IS_DEV = process.env.NODE_ENV === "development";

export const attachCallback = ({
  key,
  callback,
}: {
  key: string;
  callback: (...args: unknown[]) => void;
}): void => {
  const quoteformElement = IS_DEV
    ? document.getElementById("idg-quoteform")
    : document.getElementsByTagName("idg-quoteform")[0];
  if (!quoteformElement) return;
  quoteformElement[key] = callback;
};
