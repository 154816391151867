import { IDGHeaders } from "types";
import { EnergyType, ListMake, ModelsByMake, VersionsByModel } from "./types";

export const getMakes = (headers: IDGHeaders, graphqlDomain: string): Promise<ListMake[] | []> => {
    const endpoint = new URL(`${graphqlDomain}/graphql`);
    const query = `
    {
        listMakes(countryCode: "fr", networkId: "${headers["x-network-id"]}") {
          id
          label
        }
    }
    `;

    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        body: JSON.stringify({
            query
        })
    })
        .then((response) => {
            if (response.status >= 400) {
                throw new Error("Error fetching data");
            } else {
                return response.json();
            }
        })
        .then((data) => data.data.listMakes);
}

export const getModels = (makeId: string, headers: IDGHeaders, graphqlDomain: string): Promise<ModelsByMake[] | []> => {
    const endpoint = new URL(`${graphqlDomain}/graphql`);
    const query = `
    query ModelsByMake($makeId: String!) {
        modelsByMake(makeId: $makeId, countryCode: "fr", networkId: "${headers["x-network-id"]}") {
          id
          label
          version {
            id
            label
          }
        }
      }
    `;
    const variables = { makeId }

    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        body: JSON.stringify({
            query,
            variables
        })
    })
        .then((response) => {
            if (response.status >= 400) {
                throw new Error("Error fetching data");
            } else {
                return response.json();
            }
        })
        .then((data) => data.data.modelsByMake);
}


export const getVersions = (makeId: string, modelId: string, versionId: string, headers: IDGHeaders, graphqlDomain: string): Promise<VersionsByModel[] | []> => {
    const endpoint = new URL(`${graphqlDomain}/graphql`);
    const query = `
    query VersionsByModel(
        $makeId: String!
        $modelId: String!
        $versionId: String!
      ) {
        versionsByModel(
          makeId: $makeId
          modelId: $modelId
          versionId: $versionId
          countryCode: "fr"
          networkId: "${headers["x-network-id"]}"
        ) {
          id
          label
        }
      }
    `;
    const variables = { makeId, modelId, versionId }

    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        body: JSON.stringify({
            query,
            variables
        })
    })
        .then((response) => {
            if (response.status >= 400) {
                throw new Error("Error fetching data");
            } else {
                return response.json();
            }
        })
        .then((data) => data.data.versionsByModel);
}


export const getVersionEnergyType = (versionId: string, headers: IDGHeaders, graphqlDomain: string): Promise<EnergyType | undefined> => {
    const endpoint = new URL(`${graphqlDomain}/graphql`);
    const query = `
    query vehiclesByVersion(
        $versionId: String!
      ) {
        vehiclesByVersion (versionId: $versionId, countryCode: "fr", networkId: "${headers["x-network-id"]}") {
            numord
            energyType
          }
      }
    `;

    const { numord, modelVersionId } = extractNumordFromVersionId(versionId);
    const variables = { versionId: modelVersionId }
    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        body: JSON.stringify({
            query,
            variables
        })
    })
        .then((response) => {
            if (response.status >= 400) {
                throw new Error("Error fetching data");
            } else {
                return response.json();
            }
        })
        .then((data) => {
            const version = data.data.vehiclesByVersion
            return version?.find(
                (version) => version.numord == numord)?.energyType
        })
};


const extractNumordFromVersionId = (versionId: string): { numord: string, modelVersionId: string } => {
    const splitedVersionId = versionId.split('_');
    return {
        numord: splitedVersionId[3],
        modelVersionId: `${splitedVersionId[0]}_${splitedVersionId[1]}_${splitedVersionId[2]}`
    }
}

