import { h, VNode } from "preact";
import { withText } from "preact-i18n";
import { useState, useEffect } from "preact/hooks";
import { Category, Option, SelectedCategory } from "types";
import { LoadingIcon } from "../../icons";
import SearchFilter, { isCategories } from "../atoms/search-filter";
import Select from "../atoms/select";
import SelectWithDisclosure from "./select-with-disclosures";

interface FilteredSelectProps {
  options: Category[] | Option[];
  value?: SelectedCategory[] | Option;
  onChange: (...event: SelectedCategory[] | Option[]) => void;
  isLoading: boolean;
  handleRemoveValue?: (index: number) => void;
  withExtraInfo?: boolean;
  search: string;
}

const FilteredSelect = ({
  options,
  value,
  isLoading,
  onChange,
  handleRemoveValue,
  withExtraInfo,
  search,
}: FilteredSelectProps): VNode => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  useEffect(() => {
    setFilteredOptions(options);
  }, [options, setFilteredOptions]);
  return (
    <div className={`flex flex-col w-full gap-4`}>
      <div className="px-4">
        <SearchFilter
          options={options}
          value={isCategories(options) ? undefined : (value as Option)}
          onChange={onChange}
          placeholder={search}
          setFilteredOptions={setFilteredOptions}
        />
      </div>
      {isLoading ? (
        <div className="flex justify-center w-full mb-2">
          <LoadingIcon />
        </div>
      ) : (
        <div>
          {isCategories(options) ? (
            <SelectWithDisclosure
              values={value as SelectedCategory}
              onChange={onChange}
              categories={filteredOptions as Category[]}
              defaultOpen={filteredOptions.length < options.length}
              handleRemoveValue={handleRemoveValue}
              withExtraInfo={withExtraInfo}
            />
          ) : (
            <Select
              value={value as Option}
              onChange={onChange}
              options={filteredOptions}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default withText("search")(FilteredSelect);
