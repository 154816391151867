import { h, VNode } from "preact";
import { SelectedCategory } from "types";
import Tag from "../atoms/tag";

type TagListProps = {
  handleRemove?: (index: number) => void;
  tags: SelectedCategory[];
};

const TagList = ({ tags, handleRemove }: TagListProps): VNode => {
  return (
    <div className="flex flex-wrap">
      {tags.map((tag, index) => (
        <Tag
          key={tag.id}
          handleRemove={() => handleRemove(index)}
          label={tag.child.label}
        />
      ))}
    </div>
  );
};
export default TagList;
