import { h, VNode } from "preact";
import { Control, useWatch } from "react-hook-form";
import { Text } from "preact-i18n";
import { CarIcon, ChevronDownIcon } from "../../icons";
import VehicleTabs from "../molecules/vehicle/vehicle-tabs";
import { InputTypes, Quote } from "types";
import useVehicleModelOptions from "../../hooks/apis/use-vehicle-model";

type VehicleContainerProps = {
  control: Control<Quote>;
  selectedInput: InputTypes | undefined;
  setSelectedInput: (...event: InputTypes[]) => void;
  forceMobile: boolean;
};

const defaultLabel = <Text id="vehicle.defaultLabel" />;
const type: InputTypes = "vehicle";
const LabelToDisplay = ({
  control,
  isSelected,
}: {
  control: Control<Quote>;
  isSelected: boolean;
}): VNode => {
  const vehicle = useWatch({ control, name: "vehicle" });
  const isVehicleModelComplete =
    vehicle?.model?.make && vehicle?.model?.model && vehicle?.model?.version;
  if (!isSelected && isVehicleModelComplete) {
    const make = vehicle?.model?.make?.label;
    const model = vehicle?.model?.model?.label;
    const version = vehicle?.model?.version?.label;
    return (
      <div className="flex flex-col text-left leading-none max-w-[250px]">
        <div className="font-bold">{`${make} ${model}`}</div>
        <div className="text-sm leading-none truncate">{`${version}`}</div>
      </div>
    );
  } else if (!isSelected && vehicle?.identifier?.vehicle) {
    return (
      <div className="uppercase font-bold">
        {vehicle?.identifier?.identifier}
      </div>
    );
  }
  return <div className="leading-none">{defaultLabel}</div>;
};

export const handleSelectInput = (
  type: InputTypes,
  isSelected: boolean,
  setSelectedInput: (input: InputTypes | undefined) => void
): void => {
  if (isSelected) {
    setSelectedInput(undefined);
  } else {
    setSelectedInput(type);
  }
};

export const VehicleContainer = ({
  control,
  selectedInput,
  setSelectedInput,
  forceMobile,
}: VehicleContainerProps): VNode => {
  const isSelected = selectedInput === type;
  useVehicleModelOptions();

  return (
    <div
      className={`h-full rounded-idg shadow-idgButton bg-white w-full lg:min-w-[300px] shrink relative ${
        isSelected ? "rounded-b-none z-50" : ""
      }`}
      data-testid="vehicle-container"
    >
      <button
        className={`flex w-full h-full py-3 px-2 items-center justify-between bg-white ${
          isSelected ? "rounded-t-idg rounded-b-none" : "rounded-idg"
        }`}
        onClick={() => handleSelectInput(type, isSelected, setSelectedInput)}
        type="button"
      >
        <div className="flex w-11/12 items-center gap-3 text-left">
          <CarIcon />
          <LabelToDisplay control={control} isSelected={isSelected} />
        </div>

        <span className={`pr-2`}>
          {<ChevronDownIcon className={`${isSelected ? "rotate-180" : ""}`} />}
        </span>
      </button>
      {isSelected && (
        <div
          className={`${
            forceMobile ? "rounded-b-idg" : "lg:absolute"
          } left-0 right-0`}
        >
          <VehicleTabs />
        </div>
      )}
    </div>
  );
};

export default VehicleContainer;
